<template>
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <el-button
      v-checkbtn="isShowBtn(AUTH_BTN.goods_cate_add)"
      style="margin-bottom:20px;"
      type="primary"
      size="mini"
      icon="el-icon-plus"
      @click="$router.push({ name: 'CategoryAdd' })"
      >新增</el-button
    >
    <el-table
      :data="list"
      row-key="id"
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
      :header-row-style="{ height: '50px', color: '#626e7c' }"
      :header-cell-style="{ background: '#f8f9fb', padding: '8px 0' }"
      :cell-style="changeTr"
      :indent="30"
    >
      <el-table-column label="分类" align="left" min-width="200px">
        <template slot-scope="scope">
          <span>{{ scope.row.id }} | {{ scope.row.categoryName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="状态" align="" min-width="100px">
        <template slot-scope="{ row }" v-if="!row.children">
          <!-- <a-switch @change="changeStatus(row)" checked-children="显示" un-checked-children="隐藏" :default-checked="row['status'] == 1" /> -->
          <el-switch
            :disabled="!isShowBtn(AUTH_BTN.goods_cate_status)"
            class="switch"
            @change="changeStatus(row)"
            v-model="row['status']"
            :active-value="1"
            :inactive-value="0"
            active-text="显示"
            inactive-text="隐藏"
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column label="图标" align="" min-width="100px">
        <template slot-scope="scope"><el-image style="width: 60px; height: 60px" :src="`${scope.row.iconUrl}`"></el-image></template>
      </el-table-column>
      <el-table-column label="排序" align="" min-width="100px">
        <template slot-scope="scope">{{ scope.row.sort }}</template>
      </el-table-column>
      <el-table-column label="操作" align="" width="150px" fixed="right">
        <template slot-scope="scope">
          <el-link v-checkbtn="isShowBtn(AUTH_BTN.goods_cate_add)" :underline="false" type="primary" @click="goAdd(scope.row)">新增</el-link>
          <el-link v-checkbtn="isShowBtn(AUTH_BTN.goods_cate_edit)" :underline="false" style="margin:0 10px" type="primary" @click="goEdit(scope.row)"
            >编辑</el-link
          >
          <el-link
            v-checkbtn="isShowBtn(AUTH_BTN.goods_cate_del)"
            :underline="false"
            style="margin-left:10px"
            type="danger"
            @click="apiDelMenu(scope.row.id)"
          >
            删除
          </el-link>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { getGoodsCategoryTreeAPI, deleteGoodsCategoryAPI, changeStatusAPI } from './api'
import { authBtnMixin } from '@/mixins/authBtnMixin'
export default {
  name: 'Index',
  mixins: [authBtnMixin],
  data() {
    return {
      list: [],
      total: 0
    }
  },
  provide() {
    return {
      context: this
    }
  },
  mounted() {
    this.getGoodsCategoryTree()
  },
  methods: {
    // 新增一级菜单
    goAddRootMenu() {
      this.$router.push({ name: 'MenuDetail' })
    },
    // 新增子菜单
    goAdd(row) {
      const { id, parentId } = row
      this.$router.push({
        name: 'CategoryAdd',
        params: {
          id: id,
          parentId
        }
      })
    },
    goEdit(row) {
      const { id } = row
      this.$router.push({ name: 'CategoryEdit', params: { id: id } })
    },
    async getGoodsCategoryTree() {
      let res = await getGoodsCategoryTreeAPI()
      this.list = res || []
    },

    async apiDelMenu(id) {
      this.$confirm('此操作将永久删除该分类, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          deleteGoodsCategoryAPI(id).then(() => {
            this.getGoodsCategoryTree()
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    // 修改第一列无children的样式
    changeTr({ row, column, rowIndex, columnIndex }) {
      if (!row.children && columnIndex == 0) {
        return { paddingLeft: '23px' }
      }
    },
    handleClick(val) {
      this.getGoodsCategoryTree()
    },
    changeStatus(row) {
      let { id, status } = row
      let data = { id, status: status == 0 ? 0 : 1 }
      let statusDesc = status == 0 ? '隐藏' : '显示'

      this.$confirm(`此操作将该分类${statusDesc}吗, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          row.status = status == 0 ? 0 : 1
          changeStatusAPI(data).then(() => {
            this.getGoodsCategoryTree()
            this.$message.success('操作成功')
          })
        })
        .catch(() => {
          row.status = status == 1 ? 0 : 1
          this.$message({
            type: 'info',
            message: '已取消操作'
          })
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  /deep/.one-line {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
  }

  // .el-table /deep/ {
  //   border-top-left-radius: 5px;
  //   border-top-right-radius: 5px;
  // }
  .btn-group {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
}
</style>
<style lang="scss" scoped>
.wrapper /deep/ {
  /* switch按钮样式 */
  .switch .el-switch__label {
    position: absolute;
    display: none;
    color: #fff !important;
  }
  /*打开时文字位置设置*/
  .switch .el-switch__label--right {
    z-index: 1;
  }
  /* 调整打开时文字的显示位子 */
  .switch .el-switch__label--right span {
    // margin-left: 5px;
    margin-right: 10px;
  }
  /*关闭时文字位置设置*/
  .switch .el-switch__label--left {
    z-index: 1;
  }
  /* 调整关闭时文字的显示位子 */
  .switch .el-switch__label--left span {
    margin-left: 20px;
  }
  /*显示文字*/
  .switch .el-switch__label.is-active {
    display: block;
  }
  /* 调整按钮的宽度 */
  .switch.el-switch .el-switch__core,
  .el-switch .el-switch__label {
    width: 55px !important;
    margin: 0;
  }
}
</style>
